@import "@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss";

.personal-skills-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 0 20px 20px;
  box-sizing: border-box;

  &__header {
    width: 100%;
    padding-bottom: 40px;
    position: relative;
  }

  .AppBar {
    padding-top: 20px;
  }

  &__header-update {
    position: absolute;
    top: 90px;
    right: 0;
    color: $black-54;
    font-size: $p2-font-size;
  }

  &__header-with-icon {
    max-width: 380px;
    margin-top: 40px;
  }

  &__skills-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    margin-top: 15px;
  }

  &__skills-container {
    flex-grow: 1;
    padding: 24px 0;
    position: relative;
  }

  &__no-skills,
  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__no-skills-text {
    margin-top: 15px;
    text-align: center;
    color: $black-16;
    font-size: $p2-font-size;
  }

  &__skills-group + &__skills-group {
    margin-top: 32px;
  }

  &__skills-group-title {
    margin-bottom: 24px;
    font-size: $p2-font-size;
  }

  &__skills-items {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }

  @media screen and (min-width: 960px) {
    height: 100vh;
    padding: 0 40px 60px;

    &__header {
      max-width: 960px;
      padding-bottom: 70px;
    }

    &__header-update {
      top: 90px;
    }

    &__header-with-icon {
      margin-top: 10px;
    }

    &__skills-wrapper {
      width: 800px;
      border: 1px solid $black-16;
      border-radius: 12px;
      overflow: hidden;
    }

    &__skills-container {
      display: flex;
      flex-direction: column;
      padding: 24px;
      overflow: auto;
    }

    &__skills-group {
      display: flex;
      flex-direction: column;
    }

    &__skills-group-title {
      margin-bottom: 16px;
    }
  }
}
