@import "@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss";

.tr-card {
  box-sizing: border-box;
  width: 360px;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 16px 24px;
  border-radius: 12px;
  box-shadow: 1px 2px 8px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  text-decoration: none;

  &__left {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__type-icon {
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }

  &__label {
    color: $color-front;
    @include desktop-title-font;
  }

  &__status-icon path {
    fill: $color-interactive;
  }

  &__status-icon-container {
    border-radius: 50%;
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-interactive-light-z;
  }

  &__actions {
    display: flex;
    gap: 4px;
  }

  &:hover:not(.tr-card--completed) {
    background-color: $color-interactive-light-z;
    box-shadow: 1px 2px 12px 0px rgba(0, 0, 0, 0.21);

    .tr-card__type-icon path {
      fill: $color-front-light;
    }

    .tr-card__label {
      cursor: pointer;
      color: $color-front-light;
    }

    .tr-card__status-icon-container {
      background-color: $color-interactive-light-x;
    }
  }

  &:focus-visible {
    @include focused-outline;
  }

  &--completed {
    cursor: not-allowed;
    background-color: $color-white;

    &:hover {
      background-color: $color-white;
    }

    .tr-card__status-icon-container {
      background-color: $color-green-back;
    }

    .tr-card__label {
      pointer-events: none;
    }

    .tr-card__status-icon path {
      fill: $color-green-front;
    }
  }
}
