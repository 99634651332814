@import '../../../../../../variables';
@import "@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss";

.MobilePersonalityTestSlider {
  &__Legend {
    display: flex;
    justify-content: center;
    align-items: center;

    &__Item {
      width: 110px;
      font-size: $p2-font-size;
      color: $color-front-light;
    }

    svg {
      margin: 0 10px;
    }
  }

  &--FullLegend {
    margin-bottom: 24px;
  }
}
