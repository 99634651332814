@import '../stageCardMixins';
@import "@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss";

.StageCardCompleted {
  box-shadow: 1px 2px 8px 0px rgba(0, 0, 0, 0.12);
  cursor: not-allowed;
  @include stage-card();
  
  .Gauge {
    // TODO: Move font size to variables folder
    font-size: 36px;
    line-height: 42px;
    width: 76px;
    height: 76px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 26px;
  }

  &__Center {
    position: relative;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 26px;
  }

  &__GreenBackgroundCircle {
    position: absolute;
    background-color: $color-green-back;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    left: 50%;
    transform: translate(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
