@import 'src/variables';
@import 'src/mixins';
@import 'src/theme';
@import "@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss";

.Loader {
  align-items: center;
  display: flex;
  @include loader-size(18px);

  &__Animation {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    > div {
      -webkit-animation: bounce-delay 1.4s infinite ease-in-out both;
      animation: bounce-delay 1.4s infinite ease-in-out both;
      border-radius: 100%;
      display: inline-block;
      background-color: $green;
    }

    .Loader__Bounce__1 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }

    .Loader__Bounce__2 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }
  }

  &--Overlay {
    position: absolute;

    .Loader__Animation {
      position: fixed;
    }
  }

  &--Primary {
    .Loader__Animation {
      > div {
        background-color: $color-interactive;
      }
    }
  }

  &--Secondary {
    .Loader__Animation {
      > div {
        background-color: $color-interactive;
      }
    }
  }

  &--Tertiary {
    .Loader__Animation {
      > div {
        background-color: $color-interactive;
      }
    }
  }
}

@-webkit-keyframes bounce-delay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes bounce-delay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
