@import '../../variables';

.Stages {
  height: 100vh;
  min-width: 320px;

  .AppBar {
    padding: 20px 20px 0 20px;
    max-width: 960px;
    min-width: 320px;
    margin-left: auto;
    margin-right: auto;
  }

  .StageCardsListContainer__NotificationWrapper {
    padding: 20px;

    .Notification {
      margin: auto;
      max-width: 350px;
    }
  }

  .Loader {
    &--Overlay {
      height: calc(100% - 90px);
      width: calc(100% - 40px);
    }
  }
}
