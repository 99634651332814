// Colors

$purple: #b75bff;
$blue: #10b7ff;
$dark-blue: #0c5dff;
$white: #ffffff;
$black: #2b2b2b;
$green: #00d3ad;
$gray: #757575;

// Font sizes

$h1-font-size: 48px;
$h2-font-size: 28px;
$title-font-size: 24px;
$p1-font-size: 18px;
$p2-font-size: 14px;

// Line heights

$large-line-height: 56px;
$medium-line-height: 21px;
$small-line-height: 16px;

// Boldness

$medium-bold: 500;

// Button

$button-border-radius: 8px;

// Navbar

$navbar-height: 72px;
$navbar-padding: 20px;
$navbar-max-width: 1170px;

// Boldness

$medium-bold: 500;

// Form

$form-input-height: 46px;
$form-input-border-bottom: 2px solid $black;

// Color opacity

$huge-opacity: 0.16;
