@import "@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss";

.DesktopPersonalityTestQuestion {
  display: flex;
  width: 100%;

  &__Number {
    display: flex;
    justify-content: center;
    @include desktop-title-font;
    color: $color-front;
    width: 42px;
    margin-right: 30px;
    align-items: center;
  }

  &__Text {
    @include desktop-title-font;
    color: $color-front;
    display: flex;
    justify-content: flex-start;
    width: 480px;
    margin-right: 12px;
    word-break: break-word;
    align-items: center;
  }

  &__SliderWrapper {
    width: 360px;
  }
}
