.InductiveTest {
  display: flex;
  flex-direction: column;

  &__QuestionViewWrapper {
    display: flex;
    flex: 1;

    .InductiveTestQuestionView {
      flex: 1;
    }
  }
}

@media screen and (min-width: 960px) {
  .InductiveTest {
    &__QuestionViewWrapper {
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .InductiveTestQuestionView {
        max-height: 500px;
        box-sizing: border-box;
        width: 100%;
      }
    }
  }
}
