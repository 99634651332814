@import '../../../../variables';

.CandidateInformationMobile {
  display: flex;
  flex-direction: column;

  &__Nav {
    padding: 20px 20px 0 20px;
    height: 72px;
    max-width: 960px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }

  .CandidateInformationFormContainer {
    box-sizing: border-box;
    padding: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
    height: calc(100% - 72px);
    max-width: 420px;
    width: 100%;
    margin: auto;

    &__LoaderWrapper {
      flex: 1;
      display: flex;
      align-items: center;
      height: calc(100% - 72px);
      justify-content: center;
    }

    &__TitleWrapper {
      flex: 1;
      display: flex;
      align-items: center;

      .CandidateInformationTitle {
        width: 100%;
      }
    }

    .CandidateInformationForm {
      flex: 2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .FormFieldLabel {
        &:not(:last-child) {
          margin-bottom: 10px;
        }

        .Input,
        .Select,
        .DateInput {
          height: $form-input-height;
        }
      }
    }
  }
}
