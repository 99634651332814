@import '../../../../variables';

.CandidateInfo {
  display: flex;
  align-items: center;

  &__Avatar {
    margin-right: 12px;
  }

  &__CandidateName {
    font-size: $p1-font-size;
    line-height: $medium-line-height;
  }
}
