.EqualityAndDiversityFormContainer {
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: min-content;
  width: 100%;
  position: relative;

  &__TitleWrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .EqualityAndDiversityFormContainerTitle {
      height: 140px;
      width: 100%;
    }

    .EqualityAndDiversityFormContainer__Subtitle {
      margin: 10px 0;

      &__Button {
        display: inline-block;
        text-decoration: underline;
        padding: 0;
        height: 16px;

        &:hover {
          background-color: transparent !important;
        }
      }
    }
  }

  .EqualityAndDiversityFormContainerLogos {
    display: flex;
    justify-content: center;

    &__LogoImage {
      display: block;
    }
  }

  &__LoaderWrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
