@import "@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss";

.SingleLegendSlider {
  &__Legend {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;

    &__Item {
      @include desktop-body-font;
      color: $color-front;
    }

    svg {
      margin: 0 10px;

      path {
        fill: $color-front;
      }
    }
  }
}
