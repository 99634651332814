@import '../../../../../../variables';

.InductiveTestQuestion {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  min-height: min-content;
  align-items: center;
  width: 100%;

  .InductiveTestQuestionItem,
  .InductiveTestQuestionAnswerPreview {
    margin: 7px;
  }
}

.InductiveTestQuestion--Mobile {
  justify-content: space-between;
}
