@import "@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss";

.MobileVerbalTest {
  &__Testlet {
    &__TextWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto;
      padding: 20px 20px 0 20px;
      height: calc(100vh - 500px);
      overflow-y: scroll;
      color: $color-front;

      .TextInstruction {
        font-weight: 500;
        margin-bottom: 20px;
      }
    }

    &__Text {
      overflow: auto;
      line-height: 1.5;
    }

    &__QuestionWrapper {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      background-color: #fff;

      .ExpandableSliderTestQuestionHeader {
        &__Main {
          p {
            line-height: 1.5;
          }
        }
      }

      .SubmitButton {
        display: flex;
        justify-content: center;
        text-align: center;
        margin: 0 auto 20px;
      }
    }
  }
}
