@import './variables';
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,500&display=swap');

* {
  font-family: 'Roboto';
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1;
  padding: 0;
  margin: 0;
  border: none;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.Wrapper {
  max-width: 1170px;
  margin: 0 auto;

  @media screen and (max-width: 1440px) {
    max-width: 960px;
  }
}

.ClickableZone {
  width: 48px;
  height: 48px;
  background-color: inherit;
  position: relative;
  outline: none;
  cursor: pointer;

  img,
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

p,
h1,
h2,
span {
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.page-loader-wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

// Fixes issue with outline being applied to radio button group
// even when click is used. The issue is not present in the bit.cloud.
body.using-mouse [class^='radio-button_image'] {
  outline: none !important;
}

.unselectable {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.AbsolutelyCentered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
