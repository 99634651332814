@mixin loader-size($size) {
  &__Animation {
    > div {
      height: $size;
      width: $size;
    }
  }

  &--Overlay {
    .Loader__Animation {
      top: calc(50% - calc($size / 2));
      left: calc(50% - calc($size * 3 / 2));
    }
  }
}

@mixin circle-div($size) {
  min-width: $size;
  max-width: $size;
  min-height: $size;
  max-height: $size;
  width: $size;
  height: $size;
  border-radius: 50%;
}
