.EqualityStatementButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &__TextQuestionMark {
    display: flex;
    border: 2px solid;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    font-weight: 500;
  }
}
