.form-container {
  display: flex;
  flex-direction: column;
  max-width: 490px;
  justify-content: center;
  margin: 30px auto;
  &__actions {
    display: flex;
    justify-content: center;
  }
}

.rs__menu {
  padding-right: 1px;
  ::-webkit-scrollbar {
    width: 10px;
    min-height: 10px;
  }
  .rs__menu-list {
    max-height: 700px !important;
  }
  /* rsack */
  ::-webkit-scrollbar-rsack {
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    margin-right: 10px;
    background: rgba(0, 0, 0, 0.16);
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.26);
  }
}
