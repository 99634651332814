@import '../../../../variables';

.CandidateInformationDesktop {
  max-height: 90vh;
  min-height: 530px;
  border-radius: 12px;
  box-shadow: 2px 4px 16px 0 rgba(0, 0, 0, 0.12);
  padding: 40px 20px 40px 20px;
  box-sizing: border-box;
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 440px;

  &__Actions {
    display: flex;

    &__Button {
      line-height: 1.57;
      font-size: 14px;
      font-weight: $medium-bold;
      text-transform: uppercase;
      background-color: transparent;
      color: $green;
      margin-left: -4px;
    }
  }

  .CandidateInformationFormContainer__LoaderWrapper,
  .CandidateInformationFormContainer {
    width: 380px;
  }

  .CandidateInformationFormContainer {
    flex: 1;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-height: min-content;
    width: 100%;

    &__TitleWrapper {
      flex: 1;
      display: flex;
      align-items: center;

      .CandidateInformationTitle {
        width: 100%;
      }
    }

    &__LoaderWrapper {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .CandidateInformationForm {
      flex: 2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .FormFieldLabel {
        &:not(:last-child) {
          margin-bottom: 10px;
        }

        .Input,
        .Select {
          height: 46px;
        }
      }
    }
  }
}
