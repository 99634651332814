@import "@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss";

.rs-form {
  width: 100%;
  padding-bottom: 20px;
  .AppBar {
    padding: 20px 20px 0;
    max-width: 960px;
    min-width: 320px;
    margin-left: auto;
    margin-right: auto;
  }
  &__LoaderWrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 85vh;
  }
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin-top: 30px;
    margin-bottom: 40px;
    gap: 30px;
    &__icon {
      width: 40px;
      height: 40px;
      object-fit: contain;
    }
    &__heading {
      font-size: 24px;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: center;
      color: #000;
    }
  }

  .form-container {
    padding: 0 20px;
    .form-container_field {
      :not([class*='text-area_sz-text-area_error__']) {
        [class^='text-area-module_sz-text-area__title'] {
          color: $color-front;
        }
      }

      //text-area margin
      fieldset {
        margin-bottom: 48px;
      }

      .sz-field-label {
        display: block;
        margin-bottom: 28px;
      }

      .sz-select__single-value {
        color: $color-front;
      }
    }
  }

  .form-container__actions {
    justify-content: flex-end;
  }
}
