@import '../../../../../../variables';

.EqualityAndDiversityFormContainerTitle {
  width: 100%;

  &__Text {
    font-weight: $medium-bold;
    font-size: $h2-font-size;
  }
}
