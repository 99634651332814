@import 'src/variables';

.Notification {
  display: flex;
  padding: 12px 16px;
  border-radius: 8px;

  &__Message {
    margin-left: 16px;
    font-size: $p2-font-size;
    font-weight: $medium-bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    min-width: 24px;
  }
}

.Notification--Blue {
  background-color: rgba($color: $blue, $alpha: 0.1);

  svg {
    path {
      fill: $blue;
    }
  }

  .Notification__Message {
    color: $blue;
  }
}

.Notification--Green {
  background-color: rgba($color: $green, $alpha: 0.1);

  svg {
    path {
      fill: $green;
    }
  }

  .Notification__Message {
    color: $green;
  }
}

.Notification--Purple {
  background-color: rgba($color: $purple, $alpha: 0.1);

  svg {
    path {
      fill: $purple;
    }
  }

  .Notification__Message {
    color: $purple;
  }
}

.Notification--Claps {
  svg {
    width: 38px;
    height: 24px;
  }
}
