@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.logo {
    width: 160px;
    height: 80px;
    object-fit: contain;
}

.companyName {
    @include desktop-body-font;
    color: $color-front;
}