.rc-slider-disabled .rc-slider-handle {
  cursor: default !important;
}

.slider {
  padding: 20px 0px;
  position: absolute;
  width: 71%;
  left: 103px;

  .rc-slider-disabled {
    background-color: transparent !important;
    cursor: default !important;
  }
  .rc-slider {
    .rc-slider-dot {
      padding: 0px 15px 15px 0px;
      background-color: transparent;
      border: 0;
      width: 20px;
      height: 20px;
    }

    .rc-slider-handle {
      user-select: none;
    }
  }
  .rc-slider-rail {
    background-color: transparent !important;
    height: 2px !important;
    border-radius: 2px !important;
  }
}
.initial-slider {
  padding: 20px 0px;

  position: absolute;
  width: 71%;
  left: 103px;
  .rc-slider-disabled {
    background-color: transparent !important;
    cursor: default !important;
  }
  .rc-slider {
    .rc-slider-dot {
      margin-bottom: 15px;
      background-color: rgba(0, 0, 0, 0.16);
      border: 0;
      width: 4px;
      height: 4px;
      cursor: default !important;
    }

    .rc-slider-handle {
      user-select: none;
    }
  }
  .rc-slider-rail {
    background-color: rgba(0, 0, 0, 0.16) !important;
    height: 2px !important;
    border-radius: 2px !important;
  }
}
.handler-label {
  position: absolute;
  bottom: -20px;
  width: 200px;
  color: #10b7ff;
  text-align: center;
}
.hide {
  display: none;
}
