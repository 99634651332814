@import "@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss";

$progressBarHeight: 4px;

.ProgressBar {
  width: 100%;
  border-radius: 2px;
  height: $progressBarHeight;

  &__Progress {
    border-radius: 2px;
    height: $progressBarHeight;
  }
}

.ProgressBar--Green {
  background-color: rgba($color-interactive-light-z, 0.2);

  .ProgressBar__Progress {
    background-color: $color-interactive;
  }
}
