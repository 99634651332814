@import "@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss";

.EqualityAndDiversityForm {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .FormFieldLabel {
      &:not(:last-child) {
        margin-bottom: 10px;
      }

      .Input,
      .Select {
        height: 46px;
      }
    }

    &__Fields {
      margin: 10px 0;
  
      :not([class*='text-area-module_sz-text-area_error__']) {
        [class^='text-area-module_sz-text-area__title'] {
          color: $color-front;
        }
      }
  
      .sz-field-label {
        display: block;
        margin-bottom: 28px;
      }
  
      .ci-form[class^="text-area-module_sz-text-area"] {
        margin-bottom: 48px;
      }
  
      .sz-select__single-value {
        color: $color-front;
      }
    }

    &__SubmitButton {
      width: 100%;
    }

    &__Actions {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &__Actions__SkipButton {
      margin: auto;
    }
}