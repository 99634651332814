@import "@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss";

.sz-carousel {
  display: flex;
  flex-direction: row;

  .sz-carousel-arrow-icon {
    display: flex;
    align-items: center;
    svg {
      height: 24px;
      width: 24px;
    }

    &_faded {
      svg {
        path {
          fill: $black-16;
        }
      }
    }
  }

  .slick-list {
    flex: 1;
  }

  .sz-carousel-arrow-icon_previous {
    svg {
      transform: rotate(90deg);
    }
  }

  .sz-carousel-arrow-icon_next {
    svg {
      transform: rotate(-90deg);
    }
  }
}
