@import "@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss";

.personal-skills-profile-header {
  padding: 25px 0;
  position: sticky;
  top: 0;
  background-color: $white;
  z-index: 10;

  &--sticky {
    padding-left: 20px;
    padding-right: 20px;
    margin-left: -20px;
    margin-right: -20px;
    box-shadow: 1px 2px 6px rgba($black-100, 0.1);
  }

  &__top {
    display: flex;
    align-items: center;
  }

  &__bottom {
    margin-top: 24px;
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    padding: 3px 14px 3px 24px;
    border: 1px solid $black-16;
    border-radius: 8px;
    font-size: $p2-font-size;
    font-weight: $medium-bold;
  }

  .dropdown__list--align-right {
    margin-right: -14px;
  }

  &__no-knowledge-toggle {
    display: flex;

    .switch__label {
      gap: 8px;
      cursor: pointer;
      user-select: none;
    }
  }

  &__no-knowledge-toggle-chart {
    width: 16px;
    height: 16px;
    
    margin-top: -5px;
  }

  &__no-knowledge-toggle-label {
    display: flex;
    align-items: center;
  }

  &__add-btn {
    margin-left: 16px;

    .button__content {
      display: flex;
      align-items: center;
    }
  }

  &__add-btn-icon {
    width: 15px;

    path {
      fill: #fff;
    }
  }

  &__search-option-label {
    font-weight: $medium-bold;

    &--existing-skill {
      color: $blue;
    }
  }

  &__search-option-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 16px;
  }

  &__search-option-icons--two-icons .search__option--with-icon {
    padding-right: 65px;
  }

  &__search-option-bar-chart {
    width: 16px;
    height: 16px;
  }

  .search__option-label-text::first-letter {
    text-transform: uppercase;
  }

  [class^="sz-dropdown-menu-module_dropdown-menu-container"] {
    right: initial;
  }

  .skills-sort {
    margin-left: 12px;
  }

  @media screen and (min-width: 960px) {
    padding: 16px 24px;
    border-bottom: 1px solid $black-16;

    &__controls {
      justify-content: flex-start;
      padding: 0;
      border: none;
    }

    &__no-knowledge-toggle {
      margin-top: 0;
      margin-left: 26px;

      .switch__label {
        gap: 6px;
      }
    }

    &__no-knowledge-toggle-label {
      gap: 6px;
    }

    &__search-wrapper {
      width: 320px;
    }
  }
}

.personal-skills-profile-header-dropdown .dropdown-item .dropdown-item__label  {
  margin-right: 21px;
}