@import '../../../variables';

.NavbarWithBackLink {
  display: flex;
  justify-content: space-between;
  height: $navbar-height;
  max-width: 960px;
  min-width: 320px;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  .BackArrow {
    width: 24px;
    height: 24px;
    &:focus {
      outline: 2px solid black;
    }
  }
}
