@import "@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss";

.skill-proficiency-modal {
  min-width: 90%;
  width: auto !important;

  &__skills-level-not-set {
    width: 90%;
    text-align: center;
  }

  &__title {
    &-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 16px;
      font-size: $p1-font-size;
      font-weight: $medium-bold;
    }

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &__option {
    width: 96px;
    height: 140px;
  }

  &__title-icon {
    flex: 14px 0 0;
    min-width: 14px;
    margin-right: 10px;
  }

  &__desc {
    font-size: $p2-font-size;
    text-align: center;
    color: $grey;
  }

  &__skills-levels {
    display: flex;
    justify-content: space-around;
    padding: 110px 0 28px;
    position: relative;
  }

  &__skills-level-not-set {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__buttons {
    display: flex;
    justify-content: center;
    column-gap: 16px;
  }

  // Until this resolution width was 90%, that's why 0.9
  @media screen and (min-width: calc(400px / 0.9)) {
    min-width: 400px;
  }
}
