.module_selector_dropdown_container {
  max-width: 188px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  box-sizing: border-box;
  position: absolute;
  top: 40px;
  left: -12px;
  right: 0px;
  z-index: 999;
  cursor: pointer;
}
