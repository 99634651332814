@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.container {
  color: $color-front;

  .description {
    @include desktop-body-font;
    padding-bottom: 8px;
  }

  .title {
    @include desktop-title-medium-font;
  }

  .formContainer {
    padding-top: 32px;

    .formDescription {
      padding: 8px 0px 20px;
      @include desktop-caption-font;
      color: $color-front-light;
    }

    .checkbox {
      &:first-of-type {
        margin-bottom: 4px;
      }
    }

    .cta {
      margin: 32px auto 0px;
    }
  }

  .error {
    @include desktop-caption-font;
    color: $color-red-front;
    padding-top: 8px;
  }
}
