@import '../../../../../variables';

.TestHeader {
  &__Main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    max-width: 550px;
    margin: 0 auto;
    max-height: 88px;
  }

  svg,
  path {
    max-height: 20px;
  }

  [class^='countdown'] {
    white-space: nowrap;
    @media only screen and (max-width: 317px) {
      white-space: normal;
    }
  }
}

@media only screen and (min-width: 960px) {
  .TestHeader {
    &__Main {
      max-width: 1000px;
    }
  }
}
