@import "@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss";

.InductiveTestQuestionAnswerPreview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: min-content;
  min-width: min-content;

  &__Main {
    width: 100%;
    border: 2px solid $color-interactive;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2px;
    box-sizing: border-box;

    &--Empty {
      background-color: rgba($color-interactive, 0.1);
      color: $color-interactive;
      font-size: 28px;
      font-weight: $medium-bold;
      box-sizing: border-box;
      padding: 0;
    }
  }

  &__Index {
    margin-top: 8px;
  }
}
