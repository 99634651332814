@import '../../../../../variables';
@import "@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss";

.SliderTestQuestion {
  color: $color-front;
  &__Header {
    &__Heading {
      max-width: 1000px;
      padding: 20px;
      margin: auto;
      display: flex;
      font-size: $p2-font-size;
      text-align: left;
      box-sizing: border-box;

      &__QuestionIndex {
        margin-right: 16px;
      }

      &__QuestionText {
        font-weight: $medium-bold;
      }
    }
  }

  &__Content {
    max-width: 1000px;
    margin: auto;
    padding: 0 20px 20px 20px;
    box-sizing: border-box;

    .ColoredText {
      height: 56px;
      width: 200px;
      margin: 0 auto 10px auto;
    }

    .Slider {
      width: 100%;
      margin-bottom: 20px;
    }
  }
}
