.StageCardsList {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  min-height: calc(100% - 72px);
  max-width: 420px;
  min-width: 320px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;

  .StageCardCompleted,
  .StageCardInactive,
  .StageCardActive {
    margin-bottom: 16px;
  }
}
