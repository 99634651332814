.hint-icon {
  fill: rgba($color: #000000, $alpha: 0.5);
  cursor: pointer;
  &:hover {
    fill: rgba($color: #000000, $alpha: 1);
  }
}
.tool_tip {
  position: relative;
  display: flex;
  align-items: center;
  /* font-size: 18px; - use only if required
      /* line-height: 28px; - use only if required */
}

/* Tooltip */
.help-tip {
  margin-left: 3px;
  z-index: 1;
}

.help-tip:hover p {
  display: block;
  transform-origin: 100% 0%;
  -webkit-animation: fadeIn 0.3s ease-in-out;
  animation: fadeIn 0.3s ease-in-out;
}

.help-tip p {
  display: none;
  text-align: left;
  background-color: #fff;
  padding: 15px;
  width: 200px;
  position: absolute;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
  left: 0px;
  color: #000;
  font-size: 13px; /* Change the font size inside tooltip */
  line-height: 1.4;
  a:link,
  a:visited {
    background-color: #fff;
    text-align: center;
    display: inline-block;
    color: blue;
    text-decoration: underline;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.6);
  }

  100% {
    opacity: 100%;
    transform: scale(1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}

/* Adjust tooltip size on mobile devices */
@media screen and (max-width: 480px) {
  .help-tip p {
    width: 200px;
  }
}
