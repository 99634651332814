@import '../../../../../variables';

.InductiveTestQuestionView {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: min-content;
  padding: 20px;

  &__QuestionWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: min-content;
    max-width: 500px;
    width: 100%;
  }

  &__Text {
    margin-top: 10px;
    font-size: $p2-font-size;
    color: $gray;
  }

  .InductiveTestQuestionAnswers {
    margin-top: 10px;
    margin-bottom: 15px;
  }

  &__SquareButtonWrapper {
    width: 100%;
    max-width: 380px;
  }
}

@media screen and (min-width: 960px) {
  .InductiveTestQuestionView {
    justify-content: center;

    &__QuestionWrapper {
      max-width: 1000px;
      flex: 0;
    }

    &__Text {
      margin-top: 40px;
    }

    &__SquareButtonWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
