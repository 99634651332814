@import "@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss";

.TRWidth {
  min-width: 600px !important;
  min-height: 700px !important;
}

.ThankYouDefaultTemplate {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 380px;
  min-width: 320px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  min-height: 388px;
  height: 100%;

  &__Logo {
    margin-bottom: 10px;
    max-width: 205px;
    max-height: 40px;

    path {
      fill: $black;
    }
  }

  &__IconWrapper {
    width: 72px;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color-blue-back;
    padding: 5px;
    border-radius: 100%;
  }

  &__Icon {
    width: 40px;
    height: 40px;
    path {
      fill: $color-interactive-light;
    }
  }

  &__Title {
    margin-top: 40px;
    font-weight: 500;
    font-size: $h2-font-size;
    margin-bottom: 10px;
  }

  &__Username {
    margin-bottom: 40px;
    font-size: $p1-font-size;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: grey;
  }

  &__Text {
    margin-top: 20px;
    font-size: $p1-font-size;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
  }

  &__Center {
    color: $color-front !important;
  }

  &__Button {
    width: 230px;
    margin-top: 30px;
  }
}

@media only screen and (max-device-width: 420px) {
  .ThankYouDefaultTemplate {
    justify-content: space-between;

    &__Center {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    &__Button {
      width: 230px;
      margin-top: 30px;
    }
    &__Loader {
      margin-top: 30px;
    }
  }
}

@media only screen and (min-device-width: 420px) {
  .ThankYouDefaultTemplate {
    justify-content: center;

    &__Center {
      display: flex;
      flex: 1;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      height: calc(100% - 120px);
      width: 100%;
    }

    &__Button {
      width: 230px;
      margin-top: 30px;
    }
    &__Loader {
      margin-top: 30px;
    }
  }
}
