@import '../stageCardMixins';

.StageCardInactive {
  cursor: not-allowed;
  @include stage-card();

  &__StatusText,
  &__Name,
  &__Stage,
  &__Heading__CompanyName {
    color: $color-disabled-front;
    pointer-events: none;
  }
}
