.MobileNumericalTest {
  &__Testlet {
    padding: 10px;
  }

  &__Testlet__QuestionWrapper {
    width: 100%;
    background-color: #fff;
  }

  &__SubmitButton {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
}
