@import '../../../../../variables';

.ColoredText {
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  &--Blue {
    color: $blue;
    background-color: rgba($blue, 0.1);
  }

  &--Purple {
    color: $purple;
    background-color: rgba($purple, 0.1);
  }

  &--Gray {
    color: rgba($black, 0.7);
    background-color: rgba($black, 0.1);
  }
}
