.button.logout-button {
  padding: 0;
  height: 24px;
  &:focus {
    outline: 2px solid black;
  }
  .button__content {
    height: 24px;
  }

  svg {
    path {
      fill: rgba(0, 0, 0, 0.54);
    }
  }

  &:focus {
    outline: 2px solid black;
  }
}
