@import "@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding-top: 80px;
  box-sizing: border-box;
  color: $color-front;

  .title {
    @include desktop-headline-medium-font;
    color: $color-front;
    line-height: 28px;
    text-align: center;
  }

  .pledge {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .description {
    @include desktop-body-font;
  }

  [class^='checkbox-module_checkbox__bottom-text'] {
    margin-top: 2px;
  }

  @media only screen and (min-device-width: 480px) {
    min-width: 380px;
    margin-left: -10px;
  }
}
