@import "@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss";

.sz-header-with-icon {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 8px;

  &__icon {
    height: 40px;
    width: 40px;
  }

  &__title {
    @include desktop-headline-font;
  }

  &__description {
    @include desktop-body-font;
    text-align: center;
  }
}
