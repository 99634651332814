@import '../../../../../../../variables';

.InductiveTestQuestionItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .SVGRenderer {
    border: 1px solid $white;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__Index {
    margin-top: 8px;
  }
}
