@import "@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss";

// Trengo iframe should be hidden in test view.
#trengo-web-widget {
  display: none !important;
  visibility: hidden !important;
  z-index: -999999 !important;

  & > * {
    display: none !important;
    visibility: hidden !important;
  }
}

.TestInfoContainer {
  display: flex;
  flex-direction: column;
  color: $color-front;

  .TestInfo {
    flex: 1;
  }
}