.form-container {
  display: flex;
  flex-direction: column;
  max-width: 490px;
  justify-content: center;
  margin: 30px auto;
}
.form-label-with-hint {
  min-height: 16px;
  font-size: 14px;
  line-height: 16px;
  display: none;
  text-align: left;
}
.question-hint {
  left: 100 !important;
  right: 0 !important;
}
.actions {
  margin: 40px 0px;
  display: flex;
  justify-content: center;
}
.tr__menu {
  padding-right: 1px;
  ::-webkit-scrollbar {
    width: 10px;
    min-height: 10px;
  }
  .tr__menu-list {
    max-height: 700px !important;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    margin-right: 10px;
    background: rgba(0, 0, 0, 0.16);
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.26);
  }
}
.employee-input {
  .tr__menu {
    width: 183% !important;
    max-height: 275px !important;
  }

  .tr__menu-list {
    max-height: 275px !important;
  }
}

.hint {
  .form-label-with-hint {
    display: block;
  }
  .FormField__Label__Text {
    display: none;
  }
}
.levels {
  display: flex;
  gap: 0px 20px;
  justify-content: space-between;
  &__field {
    flex: 1;
  }
}
.roles {
  margin-top: 40px;
  &__question {
    margin-bottom: 10px;
    font-size: 15px;
  }
  &__form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px 20px;
    &__field {
      min-width: 200px;
    }
    &__field:nth-child(odd) {
      flex: 2;
    }
    &__field:nth-child(even) {
      flex: 1;
    }
    .FormField__Label__Text {
      display: none;
    }
    .form-label-with-hint {
      display: none;
    }
  }
}
.successors {
  &__question {
    margin-bottom: 10px;
    font-size: 15px;
  }
  &__form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px 20px;
    &__field {
      min-width: 200px;
    }
    &__field:nth-child(odd) {
      flex: 2;
    }
    &__field:nth-child(even) {
      flex: 1;
    }
    .FormField__Label__Text {
      display: none;
    }
    .form-label-with-hint {
      display: none;
    }
  }
}
.others {
  margin-top: 40px;
}
