@import "@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss";

@mixin stage-card() {
  min-height: 216px;
  padding: 12px;
  position: relative;
  border-radius: 12px;
  border: 1px solid $color-disabled-back;

  &:focus-visible {
    @include focused-outline;
  }

  &__Heading {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;

    &__CompanyLogo {
      max-height: 40px;
      max-width: 60px;
    }

    &__CompanyName {
      border: 1px solid $color-front;
      padding: 10px;
    }
  }

  &__StatusText {
    @include desktop-headline-font;
    margin-top: 34px;
    text-align: center;
    color: $color-front;
    margin-bottom: 50px;
  }

  &__Name {
    @include desktop-headline-font;
    color: $color-front;
    text-align: center;
  }

  &__Stage {
    @include desktop-title-font;
    color: $color-front-light;
    text-align: center;
  }
}
