@import '../../../../../variables';
@import "@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss";

.MobilePersonalityTest {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 50px);

  .MobilePersonalityTestQuestion {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;

    &__Text {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      max-width: 280px;
      font-size: $h2-font-size;
      font-weight: $medium-bold;
      word-break: break-word;
      color: $color-front;
    }

    &__AnswerPreviewWrapper {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .MobilePersonalityTestSlider {
      width: 100%;
    }

    &__SubmitButton {
      margin-top: 40px;
      max-width: 380px;
    }
  }
}

// Media query to target retina devices like iphone and ipad
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (-o-min-device-pixel-ratio: 3/2),
  only screen and (min--moz-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  .MobilePersonalityTest {
    height: calc(100vh - 120px);
  }
}
