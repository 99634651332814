.TalentReviewList {
  &__NotificationWrapper {
    padding: 20px;

    .Notification {
      margin: auto;
      max-width: 350px;
    }
  }
  .TRList {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 61px;
    &__List {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .TRBrand-details {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 40px;

      &__brandType {
        margin-top: 46px;
        font-weight: 600;
        font-size: 24px;
      }
      &__name {
        margin-top: 3px;
        font-size: 18px;
        line-height: 26px;
        color: rgba(0, 0, 0, 0.54);
      }
    }

    &__viewInstruction {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      color: rgba(0, 0, 0, 0.54);

      svg {
        path {
          fill: rgba(0, 0, 0, 0.54);
        }
      }

      &:hover {
        color: #000;
        cursor: pointer;

        svg {
          path {
            fill: #000;
          }
        }
      }

      span {
        margin-left: 8px;
        font-size: 14px;
      }
    }
  }
}
.notification-action {
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
