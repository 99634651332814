.DesktopNumericalTest {
  height: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(0, 0, 0, 0.04) 50%,
    rgba(0, 0, 0, 0.04) 100%
  );

  .TestHeader {
    max-width: 1040px;
    width: 100%;
    margin: auto;
    box-sizing: border-box;
  }

  .DesktopNumericalTestContainer {
    max-width: 1000px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;

    &__ImageLoaderWrapper {
      width: 450px;
      height: 450px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__ImageWrapper {
      padding: 20px;
      box-sizing: border-box;

      h3 {
        margin-bottom: 40px;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.44;
        color: rgb(0, 0, 0);
      }
    }

    &__QuestionWrapper {
      width: 500px;

      .singleNumerical-testlet {
        margin-bottom: 25px;
        padding: 10px;
      }

      &__Actions {
        display: flex;
        justify-content: flex-end;
        margin-top: 40px;
        margin-bottom: 20px;
      }
    }
  }
}
