@import '../stageCardMixins';
@import "@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss";

.StageCardActive {
  box-shadow: 1px 2px 8px 0px rgba(0, 0, 0, 0.12);
  @include stage-card();

  &__ArrowInCircle {
    background-color: $color-interactive-light-z;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    margin: 15px auto 24px auto;

    svg {
      path {
        fill: $color-interactive;
      }
    }
  }

  &:hover {
    background-color: $color-interactive-light-z;
    box-shadow: 1px 2px 12px 0px rgba(0, 0, 0, 0.21);

    .StageCardActive__ArrowInCircle {
      background-color: $color-interactive-light-x;
    }

    .StageCardActive__Name {
      color: $color-front-light;
    }
  }
}
