@import "@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss";

.DesktopVerbalTest {
  height: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(0, 0, 0, 0.04) 50%,
    rgba(0, 0, 0, 0.04) 100%
  );

  .TestHeader {
    max-width: 1040px;
    width: 100%;
    margin: auto;
    box-sizing: border-box;
  }
  .DesktopVerbalTestContainer {
    max-width: 1000px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;

    .VerbalTextContainer {
      max-width: 500px;
      margin: 0 auto;
      padding-right: 20px;
      box-sizing: border-box;
      color: $color-front;

      &__TestInstruction {
        max-width: 500px;
        line-height: 28px;
        font-weight: 500;
        margin-bottom: 20px;
        padding-top: 20px;
      }

      &__TesletText {
        line-height: 28px;
        display: flex;
        justify-content: center;
      }
    }

    &__QuestionWrapper {
      width: 500px;

      &__Actions {
        display: flex;
        justify-content: flex-end;
        margin: 40px 0;
      }
    }
  }
}
