@import "@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss";

.stage-navbar {
  display: flex;
  justify-content: space-between;
  height: 72px;
  align-items: center;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  min-width: 320px;
  max-width: 960px;

  .BackArrow {
    width: 24px;
    height: 24px;
  }

  &__note {
    color: $black-54;
    @include desktop-body-font;
  }
}
