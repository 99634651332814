@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;

  [class^='navbar'] {
    width: 100%;
    padding: 0;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 960px;

    [class^='redirect'] {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      width: calc(50% + 62px);

      [class^='back-button'] {
        margin-right: auto;
      }
    }
  }

  .content {
    flex-direction: column;
    margin: 16px auto;
    max-width: 380px;
    width: 100%;
  }

  .details {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin-bottom: 44px;
  }

  .title {
    @include desktop-headline-font;
    color: $color-front;
    text-align: center;
  }

  .sectionTitle {
    @include desktop-headline-medium-font;
    color: $color-front;
    text-align: center;
    padding-bottom: 32px;
  }

  [class^='link'] {
    padding: 0px !important;
  }
}
