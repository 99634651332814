@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.container {
  color: $color-front;

  .description {
    @include desktop-body-font;
    padding-bottom: 16px;

    span {
      @include desktop-body-medium-font;
    }
  }

  .testsContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .testItemContainer {
      display: flex;
      align-items: center;

      .rightSection {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-left: 20px;
      }

      .iconContainer {
        background-color: $color-blue-back;
        border-radius: 50%;
        padding: 12px;
        box-sizing: border-box;
        max-width: 48px;
        max-height: 48px;

        svg {
          width: 24px;
          height: 24px;
        }
      }

      .testType {
        @include desktop-body-medium-font;
      }

      .timeLabel {
        padding: 0px 3px;
      }

      [class^='tag-module'] {
        &:is([aria-disabled='true']) {
          color: $color-front-light !important;
        }
      }
    }
  }

  .disclaimer {
    @include desktop-caption-font;
    color: $color-front-light;
    padding-top: 16px;
  }

  [class^='link'] {
    @include desktop-caption-font;
    padding: 0px !important;
    padding-top: 8px !important;
  }

  .cta {
    margin: 0px auto;
    margin-top: 32px;
  }
}
