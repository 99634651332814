@import "@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss";

$tests-list-item-height: 56px;
$tests-list-item-padding: 16px;
$tests-list-icon-size: 40px;
$tests-list-item-border-radius: 12px;

.TestsListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.12);
  border-radius: $tests-list-item-border-radius;
  height: $tests-list-item-height;
  padding: $tests-list-item-padding;

  &__ButtonWrapper {
    width: 100%;
  }

  &__Right {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__Icon {
    svg {
      width: $tests-list-icon-size;
      height: $tests-list-icon-size;
    }
  }

  &__Details {
    margin-left: 16px;

    &__Title {
      font-size: $p1-font-size;
    }

    &__Time {
      font-size: $p2-font-size;
    }
  }

  &__ArrowIconContainer {
    width: $tests-list-item-height;
    height: $tests-list-item-height;
    min-width: $tests-list-item-height;
    max-width: $tests-list-item-height;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__GreyArrowIconContainer {
    width: $tests-list-item-height;
    height: $tests-list-item-height;
    min-width: $tests-list-item-height;
    max-width: $tests-list-item-height;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.2;

    svg {
      path {
        fill: rgb(0, 0, 0);
      }
    }
  }

  &__ResetBtnGroup {
    display: flex;
    justify-content: center;
    align-items: center;

    &__cancel {
      margin-right: 16px;
      font-size: 14px;
      font-weight: 500;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    &__reset {
      margin-right: 16px;
      font-size: 14px;
      font-weight: 500;
      color: #b75bff;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  &__Completed {
    width: $tests-list-item-height;
    height: $tests-list-item-height;
    min-width: $tests-list-item-height;
    max-width: $tests-list-item-height;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.TestsListItem--Completed {
  border: 1px solid rgba(0, 0, 0, 0.16);
  color: rgba(0, 0, 0, 0.44);
  box-shadow: none;

  svg {
    path {
      fill: rgba(0, 0, 0, 0.34);
    }
  }
}

.cursor {
  cursor: pointer !important;
}
