@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.stage-view-presentational {
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;

  .navbar {
    width: 100%;
    padding: 0;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    min-width: 320px;
    max-width: 960px;

    &__redirect {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      width: calc(50% + 62px);

      .navbar__back-button {
        margin-right: auto;
      }
    }

    &__back-arrow {
      width: 24px;
      height: 24px;

      &:focus {
        outline: 2px solid black;
      }
    }

    &__note {
      color: $black-54;
      @include desktop-body-font;
    }

    .expiration-notification {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 16px;
        height: 16px;
      }

      &__note {
        @include desktop-body-font;
        color: $color-front;
        margin-right: 8px;
      }

      &__tag {
        padding: 0px 3px;
      }

      @media screen and (max-width: 385px) {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }

  &__content {
    flex-direction: column;
    justify-content: center;
    margin: auto;
    max-width: 360px;
    width: 100%;
    gap: 40px;
    flex: 1;
  }

  &__details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
  }

  &__language-disclaimer {
    @include desktop-body-font;
    color: $color-front-light;
  }

  &__details-stage {
    font-size: $p1-font-size;
    color: $color-front-light;
    margin-top: 8px;
    text-align: center;
  }

  &__details-name {
    @include desktop-headline-font;
    color: $color-front;
    margin-top: 16px;
    text-align: center;
  }

  &__cards-list {
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .stage-action-card {
    width: 100%;
    height: fit-content;

    &__icon {
      width: 40px;
      height: 40px;
    }
  }

  .stage-company-logo {
    width: 160px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    &__image {
      max-width: 160px;
      max-height: 80px;
    }
  }
}

@media only screen and (max-device-width: 480px) {
  .stage-view-presentational {
    &__details {
      margin-top: 32px;
    }
  }
}
