.InductiveTestQuestionAnswers {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  min-height: min-content;
  width: 100%;

  .InductiveTestQuestionAnswer {
    margin: 5px;
  }

  &__radioGroup {
    //Prevent wrap of radio button
    & > div:first-child {
      flex-wrap: nowrap;
    }
  }
}
