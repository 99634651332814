@import "@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss";

.DesktopPersonalityTestSliderLegend {
  display: flex;
  justify-content: flex-end;

  &__Content {
    width: 342px;
    display: flex;
    justify-content: space-between;
    margin-right: 10px;
  }

  &__Item {
    display: flex;
    align-items: flex-end;
    color: $color-front-light;
    width: 62px;
    justify-content: center;
  }

  &__Item:last-child {
    text-align: right;
  }
}
