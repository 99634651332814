.CandidateInformationFormContainer {
  &__Logos {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;

    &__LogoImage {
      max-height: 30px;
      max-width: 100px;
      margin-right: 10px;
    }
  }

  &__TitleWrapper {
    margin-bottom: 32px;
  }
}
