.DesktopPersonalityTest {
  height: 100vh;
  display: flex;
  flex-direction: column;

  &__Header {
    .PersonalityTestHeader__Main {
      max-width: 960px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .DesktopPersonalityTestPage {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin: 0 auto;

    &__Content {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      &__Footer {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
      }
    }

    .DesktopPersonalityTestSliderLegend {
      margin-bottom: 20px;
      width: 100%;
    }

    .DesktopPersonalityTestQuestion {
      margin-bottom: 20px;
    }
  }
}
.notification-action {
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
