@import "@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss";

.need-more-time-modal {
  p {
    font-size: $p2-font-size;
    line-height: 1.4;
    + p {
      margin-top: 12px;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: $p1-font-size;
    font-weight: $medium-bold;
  }

  &__icon {
    width: 20px;
    margin-right: 18px;
  }

  &__button {
    margin-top: 20px;
    margin-left: auto;
  }
}
