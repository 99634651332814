@import 'src/variables';

.FormField {
  width: 100%;
  &__Label {
    &__Text {
      min-height: 16px;
      font-size: $p2-font-size;
      line-height: $small-line-height;
      text-align: left;
    }

    &__Text--HasError {
      color: $purple;
    }
  }

  &__Error {
    color: $purple;
    font-size: $p2-font-size;
    min-height: 16px;
    line-height: $small-line-height;
    margin-top: 8px;
    text-align: left;
  }
}
