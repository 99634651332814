.profile-menu {
  &-container {
    position: absolute;
    top: 40px;
    right: 0px;
    box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
    padding: 20px;
    border: 1px solid hsla(240, 8%, 46%, 0.2);
    border-radius: 5px;
    width: 200px;
    background-color: white;
    z-index: 999;
    display: flex;
    flex-direction: column;
  }
}

.profile-name {
  &-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &-label {
    font-size: 14px;
    color: #1f1f1f;
    margin: 6px 10px 6px 0;
    cursor: pointer;
    margin-left: 10px;
  }
}
