@import "@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss";

.EqualityStatement {

  &__Content {
    &__Main {
      .EqualityStatement__Title {
        margin-bottom: 20px;
        height: 140px;

        &__Text {
          font-weight: $medium-bold;
          font-size: $h2-font-size;
          text-align: center;
        }
      }

      &__Text {
        font-size: 18px;
        line-height: 1.44;
      }

      &__Text:last-child {
        margin-top: 10px;
      }
    }
  }
}

.EqualityStatement--Desktop {

  .EqualityStatement__GotItButton {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
  }
}

.EqualityStatement--Mobile {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: min-content;
  box-sizing: border-box;

  &__Nav {
    height: 52px;
    display: flex;
    align-items: center;
  }

  .EqualityStatement__Content {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: min-content;

    &__Main {
      flex: 1;
    }

    &__GotItButton {
      width: 100%;
      max-width: 380px;
    }
  }
}
