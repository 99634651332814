@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';
.container {
  color: $color-front;
  padding-bottom: 100px;

  .header {
    @include desktop-headline-font;
    margin-top: 42px;
    margin-bottom: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    @media screen and (max-width: 425px) {
      margin-top: 23px;
    }
  }

  .formWrapper {
    display: flex;
    flex-direction: column;
    max-width: 490px;
    justify-content: center;
    margin: 30px auto;
    gap: 32px;

    .submit {
      display: flex;
      justify-content: flex-end;
    }
  }

  .formFieldContainer {
    display: flex;
    flex-direction: column;

    label {
      @include desktop-title-medium-font;
      margin-bottom: 8px;
    }

    .description {
      @include desktop-caption-font;
      color: $color-front-light;
      margin-bottom: 8px;
    }

    .formField {
      margin-top: 2px;
    }
  }

  //Mobile
  @media screen and (max-width: 600px) {
    .header {
      margin-top: 23px;
    }

    .formWrapper {
      max-width: 335px;
      margin: 20px auto;
    }
  }
}
