@import '../../../../../variables';

.CandidateInformationTitle {
  height: 100px;
  margin: 10px 0;

  &__Text {
    text-align: center;
    font-weight: $medium-bold;
    font-size: $h2-font-size;
  }
}
