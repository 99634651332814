// -- This file should be deleted as soon as Spotted Zebra tests are implemented
.TPTest {
  position: relative;

  &__Cloak {
    width: 100%;
    height: 81px;
    background-color: rgb(255, 255, 255);
    position: absolute;
    bottom: 0px;

    @media screen and (max-width: 760px) {
      height: 0;
    }
  }

  .Loader {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
  }

  iframe {
    margin: 0 auto;
    display: block;
  }
}
