@import "@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss";

.skills-self-assessment-intro {
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    max-width: 460px;
    gap: 40px;
    flex: 1;
  }

  &__main-content {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  &__actions {
    display: flex;
    justify-content: center;

    .button {
      width: 220px;
    }
  }

  .page-navbar-desktop {
    display: flex;
    justify-content: space-between;

    &__back-button {
      padding: 0;

      .button__content {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
      }
    }

    &__back-button-text {
      @include desktop-body-font;
    }

    &__right {
      display: flex;
      align-items: center;
    }

    &__note {
      @include desktop-body-font;
      color: rgba(0, 0, 0, 0.54);
    }
  }

  .page-navbar-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;

    &__back-button {
      padding: 0;
      height: auto;

      .button__content {
        display: flex;
        flex-direction: row;
        align-items: center;

        svg {
          height: 24px;
          width: 24px;
        }
      }
    }

    &__note {
      @include desktop-body-font;
      color: rgba(0, 0, 0, 0.54);
    }

    &__right {
      min-width: 40px;
    }
  }
}

@media only screen and (max-device-width: 480px) {
  .skills-self-assessment-intro {
    padding: 20px 20px 40px 20px;

    &__main-content {
      flex: 1;
      justify-content: center;
    }

    &__actions {
      .button {
        width: 100%;
      }
    }
  }
}
