@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.container {
  color: $color-front;

  .title {
    @include desktop-title-medium-font;
    padding-bottom: 20px;
  }

  .checkboxContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;

    [class^='checkbox-group-item-module'] {
      align-items: flex-start !important;
    }

    span {
      &[class^='checkbox-group-item-module'] {
        max-width: 356px !important;
      }
    }

    input {
      &[class^='checkbox-group-item-module'] {
        min-width: 15px !important;
        margin-top: 3px !important;
      }
    }
  }

  .disclaimer {
    @include desktop-caption-font;
    color: $color-front-light;
    padding-top: 8px;
  }

  [class^='link'] {
    @include desktop-caption-font;
    padding: 0px !important;
    padding-top: 8px !important;
  }

  .actions {
    display: flex;
    margin-top: 32px;
    gap: 24px;
    width: 100%;

    button {
      width: 100% !important;
    }
  }
}
