@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.CandidateInformationForm {
  &__Fields {
    margin: 10px 0;

    :not([class*='text-area_sz-text-area_error__']) {
      [class^='text-area-module_sz-text-area__title'] {
        color: $color-front;
      }
    }

    .sz-field-label {
      display: block;
      margin-bottom: 28px;
    }

    .ci-form[class^='text-area-module_sz-text-area'] {
      margin-bottom: 48px;
    }

    .sz-select__single-value {
      color: $color-front;
    }
  }

  &__Actions {
    margin-top: 72px;
  }

  &__Actions__SkipButton {
    margin: auto;
    margin-top: 8px;
  }

  .FormField {
    margin-bottom: 10px;
  }
}
