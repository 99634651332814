@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.container {
  .description {
    @include desktop-body-font;
    color: $color-front;
    &:first-of-type {
      padding-bottom: 16px;
    }
  }
  .boldDescription {
    @include desktop-body-medium-font;
    color: $color-front;
    padding-bottom: 16px;
  }

  .disclaimer {
    @include desktop-caption-font;
    color: $color-front-light;
    padding-top: 8px;
  }

  .actions {
    display: flex;
    justify-content: center;
    margin-top: 32px;
    gap: 24px;
    width: 100%;
  }

  .largeButtons {
    button {
      width: 100% !important;
    }
  }

  [class^='link'] {
    @include desktop-caption-font;
    padding: 0px !important;
    padding-top: 8px !important;
  }
}
