.tr-form {
  width: 100%;
  .AppBar {
    padding: 20px 20px 0;
    max-width: 960px;
    min-width: 320px;
    margin-left: auto;
    margin-right: auto;
  }
  &__LoaderWrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 85vh;
  }
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin-top: 30px;
    margin-bottom: 40px;
    gap: 30px;
    &__icon {
      width: 40px;
      height: 40px;
      object-fit: contain;
    }
    &__heading {
      font-size: 24px;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: center;
      color: #000;
    }
  }

  .actions {
    justify-content: flex-end;
  }
}
